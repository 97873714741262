@use '../../breakpoints';

.categories-wrapper {
  user-select: none;
  -webkit-user-select: none;
  padding-top: 0.25rem;
  padding-left: 0.25rem;
  color: var(--text-color);
  background-color: var(--page-color);
}

@media (min-width: breakpoints.$side-by-side) {
  .categories-wrapper {
    display: grid;
    grid-template-rows: max-content minmax(0, 1fr);
    grid-template-columns: minmax(0, 1fr);
    padding-top: 0;
    height: 100%;
  }
}

.categories {
  padding-right: 0.875rem;
  overflow-y: auto;
}

.system {
  padding-right: 0.875rem;
  margin-bottom: 1rem;
}

@media (min-width: breakpoints.$side-by-side) {
  .system {
    padding-top: 0.25rem;
    padding-left: 0.25rem;
    border-bottom: thin black solid;
  }
}